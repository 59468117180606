import React from "react";
import Layout from "../../../other/layout";
import Detaillants from "../../../components/AC-StaticPages/VotreEntreprise/Subpages/Detaillants";
import SEO from "../../../other/seo";

export default function detaillants() {
  return (
    <Layout>
      <SEO title="Détaillants" />

      <Detaillants />
    </Layout>
  );
}
